<article *ngIf="models.length > 0" class="step-container">
  <h4 class="leading-icon">
    <sbdl-icon icon="steps"></sbdl-icon>Step By Step
  </h4>
  <ol>
    <li *ngFor="let step of models" #steps>
      <h5>{{ step.title }}</h5>
      <dynamic-html [content]="step.content"></dynamic-html>
    </li>
  </ol>
</article>
