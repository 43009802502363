<div class="top-header">
  <img src="/assets/images/yellow-spot.png" alt="" />
  <sbdl-actions [model]= "model" (readingModeChanged)="emitReadingModeChanged($event)"></sbdl-actions>
</div>
<sbdl-header [model]="model" (attachmentsClicked)="scrollToAttachments()"></sbdl-header>

<sbdl-overview [model]="model.overview" (sectionElementLoaded)="setOverview($event)"></sbdl-overview>
<hr/>

<sbdl-step-by-step [models]="model.steps" (sectionElementLoaded)="setSteps($event)"></sbdl-step-by-step>
<hr *ngIf="model.attachments.length > 0"/>

<sbdl-attachments [models]="model.attachments" [videoLinks]="model.videoLinks" (sectionElementLoaded)="setAttachments($event)"></sbdl-attachments>
<hr/>

<sbdl-section [contentHtml]="model.comments" [options]="commentsOptions" (sectionElementLoaded)="setComments($event)"></sbdl-section>
<hr *ngIf="model.comments"/>

<sbdl-formative [model]="model.formative" showProfLearningContent="true" (sectionElementLoaded)="setFormative($event)"></sbdl-formative>
