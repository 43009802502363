<article #header class="overview-container">
  <h4 class="leading-icon">
    <i class="far fa-hand-point-right"></i>Get Started
  </h4>
  <div>
    <h5>
      Overview
      <sbdl-tooltip
        text="Define what the student will be able to do after completing this resource."
      ><sbdl-icon icon="info"></sbdl-icon></sbdl-tooltip>
    </h5>
    <p>
      <sbdl-read-more [text] = "model.description"></sbdl-read-more>
    </p>
  </div>
  <div>
    <h5>Learning Goal</h5>
    <p>
      <sbdl-read-more [text] = "model.learningGoal"></sbdl-read-more>
    </p>
  </div>
  <div>
    <h5>Success Criteria</h5>
    <div [innerHtml]="model.successCriteria"></div>
  </div>
</article>
