<article *ngIf="model" class="topics-container">
  <h4 class="leading-icon">
    <i class="far fa-chart-network"></i>Topics
  </h4>
  <section>
    <p class="italics">
        The following topic/theme descriptors describe what students know and are able to do when they are above, near, 
        and below the target level.  Included are differentiated instructional resources that support learning by engaging 
        students in the formative assessment process.
    </p>
    <div class="more-info">
      <sbdl-icon icon="info"></sbdl-icon> More information on performance levels 
      <span class="scale scale-above"></span>
      <span class="scale scale-near"></span>
      <span class="scale scale-below"></span>
    </div>
    <div *ngFor="let topic of model.topics" class="topic" #topicRefs>
      <h6>{{ topic.title }}</h6>
      <div class="topic-content">
        <div [ngPlural]="topic.resourceLinks.length" class="resource">
            <ng-template ngPluralCase="=0">
              Resource: <em class="emphasis-medium">Future resource</em>
            </ng-template>
            <ng-template ngPluralCase="=1">
              Resource: <a [routerLink]="['/resource/', topic.resourceLinks[0].resourceId ]">{{ topic.resourceLinks[0].title }}</a>
            </ng-template>
            <ng-template ngPluralCase="other">
              Resource:
              <div *ngFor="let resource of topic.resourceLinks" class="resource-links">
                <a [routerLink]="['/resource/', resource.resourceId ]">{{ resource.title }}</a>
              </div>
            </ng-template>
        </div>
        <div class="scale">
          <div class="above">
            <div class="scale-title">Above</div>
            <p>
              {{ topic.above }}
            </p>
          </div>
          <div class="near">
            <div class="scale-title">Near</div>
            <p>
              {{ topic.near }}
            </p>
          </div>
          <div class="below">
            <div class="scale-title">Below</div>
            <p>
              {{ topic.below }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section #suggestionsRef class="suggestions">
    <h5>Suggestions for Intervention</h5>
    <div [innerHtml]="model.suggestionsForIntervention"></div>
  </section>
</article>