export class ScrollableElements {
    getStarted: any;
    overview: any;
    steps: any[];
    customStrategySection: ScrollableSection;
    attachments: any;
    comments: any;
    differentiation: ScrollableSection;
    formativeAssessmentProcess: any;
    topics: ScrollableSection[];
    sections: ScrollableSection[];
    assessmentInfo: any;
}

export class ScrollableSection {
    title: string;
    fontAwesomeIcon: string;
    sbdlIcon: string;
    elementRef: any;
}
