import { SectionOptions } from './section.component';

export const commentsSectionOptions: SectionOptions = {
    title: 'Things To Consider',
    fontAwesomeIcon: 'fa-comment-dots',
    iconBackground: 'blue'
};

export const instructionalUseOptions: SectionOptions = {
    title: 'Instructional Use',
    sbdlIcon: 'steps',
    iconBackground: 'yellow'
};

export const strategyInActionOptions: SectionOptions = {
    title: 'Strategy In Action',
    fontAwesomeIcon: 'fa-universal-access',
    iconBackground: 'green'
};

export const stepByStepOptions: SectionOptions = {
    title: 'Step-by-Step',
    sbdlIcon: 'steps',
    iconBackground: 'blue'
};
