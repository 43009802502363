<nav class="outline">
  <sbdl-outline
    [model]="model"
    [scrollableElements]="scrollableElements"
    [readingMode]="readingMode"
  ></sbdl-outline>
</nav>
<section class="resource-content">
  <sbdl-playlist-content
    [model]="model"
    (loadScrollableElements)="setScrollableElements($event)"
    (readingModeChanged)="readingModeChanged($event)"
  ></sbdl-playlist-content>
</section>
<aside class="metadata">
  <sbdl-detailed-metadata [model]="model.details"></sbdl-detailed-metadata>
</aside>