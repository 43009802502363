<div class="image-thumbnail">
  <img [src]="model.image">
</div>
<h5 *ngIf="model.category">{{ model.category }}</h5>
<h6 class="link-title">{{ staticData.linkTitle }}</h6>
<a *ngFor="let link of staticData.links" [href]="link.url" class="link-card" target="_blank" #links> 
  <div class="type-info">
    <span>{{ link.title }}</span>
    <i class="far {{ link.faIcon }}"></i>
  </div>
</a>