<div class="app-container">
    <div class="navigation">
        <!-- TODO: Add navigation -->
        <p><sbdl-button class ="primary" [routerLink]="['/resource/7']">IR1</sbdl-button></p>
        <p><sbdl-button class ="primary" [routerLink]="['/resource/8']">IR2</sbdl-button></p>
        <p><sbdl-button class ="primary" [routerLink]="['/resource/9']">IR3</sbdl-button></p>
        <p><sbdl-button class ="primary" [routerLink]="['/resource/10']">IR4</sbdl-button></p>
        <p><sbdl-button class ="primary" [routerLink]="['/resource/11']">IR5</sbdl-button></p>
        <p><sbdl-button class ="primary" [routerLink]="['/resource/14']">FAS1</sbdl-button></p>
        <p><sbdl-button class ="primary" [routerLink]="['/resource/15']">FAS2</sbdl-button></p>
        <p><sbdl-button class ="primary" [routerLink]="['/resource/19']">FAS3</sbdl-button></p>
        <p><sbdl-button class ="primary" [routerLink]="['/resource/16']">AS1</sbdl-button></p>
        <p><sbdl-button class ="primary" [routerLink]="['/resource/17']">AS2</sbdl-button></p>
        <p><sbdl-button class ="primary" [routerLink]="['/resource/18']">AS3</sbdl-button></p>
    </div>
    <div class="router-outlet">
        <router-outlet></router-outlet>
    </div>
</div>

