<article *ngIf="models.length > 0" #header class="attachments-container">
  <h4 class="leading-icon">
      <i class="far fa-paperclip"></i>Attachments
  </h4>
  <p>This resource relies on the following materials. Download them to use.</p>
  <a href="javascript:void(0);" *ngFor="let attachment of models" (click)="download(attachment)"  class="attachment-card"  #attachments>
    <div class="type-info">
      <span class="attachment-type">{{ attachment.type }}</span>
      <i class="far {{attachment.fileType | fileTypeIcon }}"></i>
    </div>
    <p>{{ attachment.title }}</p>
    <div class="download-text">
        Download ({{attachment.fileExtension}} {{ attachment.fileSizeInKB ? attachment.fileSizeInKB + ' KB' : '' }})
    </div>
  </a>
  <h6 *ngIf="videoLinks && videoLinks.length > 0">Videos</h6>
  <div *ngFor="let link of videoLinks" class="video-embed">
    <iframe width="560" title="Youtube Video" height="315" [src]="link | safeUrl" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
  </div>
</article>
