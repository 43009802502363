<ul id="version-stamping" style="display:none">
    <li>Version: {{ appVersion.version }}</li>
    <li>Env Name: {{ env }}</li>
    <li>Hash: {{ appVersion.hash }}</li>
    <li>Raw: {{ appVersion.raw }}</li>
    <li>Tag: {{ appVersion.tag }}</li>
    <li>Semver: {{ appVersion.semver }}</li>
    <li>Semver String: {{ appVersion.semverString }}</li>
</ul>
<sbdl-svg-defs></sbdl-svg-defs>
<router-outlet></router-outlet>