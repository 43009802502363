<article #header class="overview-container">
  <h4 class="leading-icon">
    <i class="far fa-hand-point-right"></i>Overview
  </h4>
  <div>
    <h5>
      Description
    </h5>
    <p>
      <sbdl-read-more [text] = "model.description"></sbdl-read-more>
    </p>
  </div>
  <div>
    <h5>Student Benefits</h5>
    <p>
      <sbdl-read-more [text] = "model.studentBenefits"></sbdl-read-more>
    </p>
  </div>
  <div>
    <h5>Suggested Materials</h5>
    <div [innerHtml]="model.suggestedMaterials"></div>
  </div>
</article>
