<div class="top-header">
  <img src="/assets/images/yellow-spot.png" alt="" />
  <sbdl-actions [model]= "model" (readingModeChanged)="emitReadingModeChanged($event)"></sbdl-actions>
</div>
<sbdl-header [model]="model" (attachmentsClicked)="scrollToAttachments()"></sbdl-header>

<sbdl-strategy-overview [model]="model.overview" (sectionElementLoaded)="setOverview($event)"></sbdl-strategy-overview>

<hr *ngIf="customSection.contentHtml"/>
<sbdl-section [contentHtml]="customSection.contentHtml" [options]="customSection" (sectionElementLoaded)="setCustomSection($event, customSection)"></sbdl-section>

<hr *ngIf="model.attachments.length > 0"/>
<sbdl-attachments [models]="model.attachments" [videoLinks]="model.videoLinks" (sectionElementLoaded)="setAttachments($event)"></sbdl-attachments>

<div *ngFor="let section of sections">
  <hr *ngIf="section.contentHtml"/>
  <sbdl-section [contentHtml]="section.contentHtml" [options]="section" (sectionElementLoaded)="setSection($event, section)"></sbdl-section>
</div>