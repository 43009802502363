<article #header class="formative-container">
  <h4  class="leading-icon"><sbdl-icon icon="strategies"></sbdl-icon>
    {{ showProfLearningContent ? 'Formative Assessment Strategies' : 'Formative Assesment Process' }}
  </h4>
  <section *ngIf="showHowItsUsed">
    <h5>How it's used</h5>
    <div *ngIf="model.formativeAssessmentProcess; else howItsUsed">
      <h6>Clarify Intended Learning</h6>
      <div [innerHtml]="model.formativeAssessmentProcess.clarifyIntendedLearning"></div>
      <h6>Elicit Evidence</h6>
      <div [innerHtml]="model.formativeAssessmentProcess.elicitEvidence"></div>
      <h6>Interpret Evidence</h6>
      <div [innerHtml]="model.formativeAssessmentProcess.interpretEvidence"></div>
      <h6>Act on Evidence</h6>
      <div [innerHtml]="model.formativeAssessmentProcess.actOnEvidence"></div>
    </div>
    <ng-template #howItsUsed>
      <div [innerHtml]="model.howItsUsed"></div>
    </ng-template>
  </section>
  <p *ngIf="showProfLearningContent" class="prof-learning-content ">
    These strategies were developed to be used with students. Modify as needed for adult use.
  </p>
  <section>
    <h5 *ngIf="!showProfLearningContent">Strategies Used</h5>
    <div *ngFor="let strategy of model.strategies" class="strategy-link">
      <h6><a [href]="strategy.moreAboutUrl" target="_blank">{{ strategy.title }} <i class="far fa-arrow-right"></i></a></h6>
      <p>{{ strategy.description }}</p>
    </div>
  </section>
</article>
