<div class="top-header">
  <img src="/assets/images/yellow-spot.png" alt="" />
  <sbdl-actions [model]= "model" (readingModeChanged)="emitReadingModeChanged($event)"></sbdl-actions>
</div>
<sbdl-header [model]="model" (attachmentsClicked)="scrollToAttachments()"></sbdl-header>

<sbdl-get-started [model]="model.overview"  (sectionElementLoaded)="setGetStarted($event)"></sbdl-get-started>
<hr *ngIf="model.steps.length > 0"/>

<sbdl-step-by-step [models]="model.steps" (sectionElementLoaded)="setSteps($event)"></sbdl-step-by-step>
<hr *ngIf="model.attachments.length > 0"/>

<sbdl-attachments [models]="model.attachments" [videoLinks]="model.videoLinks" (sectionElementLoaded)="setAttachments($event)"></sbdl-attachments>
<hr *ngIf="model.attachments.length > 0"/>

<sbdl-differentiation [model]="model.differentiation" (sectionElementLoaded)="setDifferentiation($event)"></sbdl-differentiation>
<hr *ngIf="model.differentiation && (model.differentiation.performanceBasedDifferentiation || model.differentiation.accessibilityStrategies.length > 0)"/>

<sbdl-section [contentHtml]="model.comments" [options]="commentsOptions" (sectionElementLoaded)="setComments($event)"></sbdl-section>
<hr *ngIf="model.comments"/>

<sbdl-formative [model]="model.formative" (sectionElementLoaded)="setFormative($event)"></sbdl-formative>
