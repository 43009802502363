<div class="resource-type">{{ model.resourceType | resourceType }}</div>
<h1 *ngIf="!showIconsCol; else titleWithIcons">{{ details.title }}</h1>
<ng-template #titleWithIcons>
  <div class="title-with-icons">
    <div class="icon-col">
      <div *ngIf="grade" class="circle">G{{ grade }}</div>
      <sbdl-icon [icon]="details.assessmentTypeIcon"></sbdl-icon>
    </div>
    <h1>{{ details.title }}</h1>
  </div>
</ng-template>
<section class="byline">
  {{ details.author }}
  <span *ngIf="details.author && details.authorOrganization">&nbsp;&nbsp;&bull;&nbsp;&nbsp;</span>
  <span class="font-light">{{ details.authorOrganization }}</span>
</section>
<span *ngIf="details.lastModified" class="caption">
  <span class="emphasis-medium">Updated</span>
  {{ details.lastModified | date: 'mediumDate' }}
</span>
<sbdl-button *ngIf="model.attachments.length > 0" class="primary" (click)="emitAttachmentsClicked()">
  <i class="far fa-paperclip"></i> Attachments
</sbdl-button>
