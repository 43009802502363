import { Component, ComponentFactoryResolver, OnInit, Type, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ResourceType } from '../data/resource/model/resource-type.enum';
import { InstructionalResourceComponent } from './instructional/instructional-resource.component';
import { PlaylistComponent } from './playlist/playlist.component';
import { ProfessionalResourceComponent } from './professional/professional-resource.component';
import { ResourceHostDirective } from './resource-host.directive';
import { ResourceComponent } from './resource.component';
import { StrategyComponent } from './strategy/strategy.component';

/***
 * This component will render the correct resource component based on the determined resource type.
 * Use the componentMap to assign a resource compoenent implementation to a ResourceType.
 */
@Component({
  selector: 'sbdl-resource-type-strategy',
  template: `<ng-template sbdlResourceHost></ng-template>`
})
export class ResourceTypeStrategyComponent implements OnInit {
  readonly componentMap: Map<ResourceType, Type<ResourceComponent>> = new Map<ResourceType, Type<ResourceComponent>>([
    [ ResourceType.Instructional, InstructionalResourceComponent ],
    [ ResourceType.Professional, ProfessionalResourceComponent ],
    [ ResourceType.AccessibilityStrategy, StrategyComponent ],
    [ ResourceType.FormativeStrategy, StrategyComponent ],
    [ ResourceType.ConnectionsPlaylist, PlaylistComponent ]
  ]);

  @ViewChild(ResourceHostDirective, {static: true}) hostDirective: ResourceHostDirective;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.route.data.subscribe(data => {
      const component = this.componentMap.get(data.resource.resourceType);
      const componentFactory = this.componentFactoryResolver.resolveComponentFactory(component);
  
      const viewContainerRef = this.hostDirective.viewContainerRef;
      viewContainerRef.clear();
      const componentRef = viewContainerRef.createComponent(componentFactory);
  
      componentRef.instance.model = data.resource;
    });
  }
}