<div class="image-thumbnail">
  <img [src]="model.image">
</div>
<div class="subjects-grades">
  <!-- TODO: should be mapped; -->
  <img *ngIf="claimImagePath" [src]="claimImagePath" alt="">
  <div>
    <h5 class="subjects">{{ model.subjects | join }}</h5>
    <h6 class="grades">
        <span [ngPlural]="model.grades.length" >
          <ng-template ngPluralCase="=1">Grade</ng-template>
          <ng-template ngPluralCase="other">Grades</ng-template>
        </span>
      {{ model.grades | join:{conjunction:'and'} }}
    </h6>
  </div>
</div>
<div class="details">
  <ng-container *ngTemplateOutlet="alignment; context: { models: model.claims, title: 'Claim', titles: 'Claims' }"></ng-container>
  <ng-container *ngTemplateOutlet="alignment; context: { models: model.targets, title: 'Target', titles: 'Targets' }"></ng-container>
  <ng-container *ngTemplateOutlet="alignment; context: { models: model.standards, title: 'Standard', titles: 'Standards' }"></ng-container>
</div>
<div *ngIf="model.relatedPlaylists.length > 0" class="playlists">
  <h6>Playlists</h6>
  <div class="caption emphasis-medium">Connections playlists utilizing this resource.</div>
  <a #playlistRef [routerLink]="['/resource', playlist.resourceId ]" *ngFor="let playlist of model.relatedPlaylists" class="playlist-card">
    <sbdl-icon [icon]="playlist.assessmentTypeIcon" class="assessment-type-icon"></sbdl-icon>
    <div class="playlist-title">
      <div class="title">{{ playlist.title }}</div>
      <div class="resources" [ngPlural]="playlist.numberOfResources" >
        <ng-template ngPluralCase="=1">{{ playlist.numberOfResources }} resource</ng-template>
        <ng-template ngPluralCase="other">{{ playlist.numberOfResources }} resources</ng-template>
      </div>
    </div>
    <sbdl-icon icon="playlist-play" class="playlist-icon"></sbdl-icon>
  </a>
</div>
<div *ngIf="model.relatedResources.length > 0">
  <h6>Playlist Resources</h6>
  <div class="caption emphasis-medium">Quick links to the referenced materials.</div>
  <a #resourceRef *ngFor="let resource of model.relatedResources" [routerLink]="['/resource', resource.resourceId ]" class="link-card"> 
    <div class="type-info">
      <span class="link-title">{{ resource.title }}</span>
      <sbdl-icon icon="steps"></sbdl-icon>
    </div>
  </a>
</div>
<ng-template #alignment let-alignments="models" let-title="title" let-titles="titles">
  <label *ngIf="alignments && alignments.length > 0" [ngPlural]="alignments.length">
    <ng-template ngPluralCase="=1">{{title}}</ng-template>
    <ng-template ngPluralCase="other">{{titles}}</ng-template>
  </label>
  <div *ngIf="alignments && alignments.length > 0" class="value">
    <div *ngFor="let alignment of alignments" title="{{ alignment.title || alignment }}">{{ alignment.title || alignment }}</div>
  </div>
</ng-template>
