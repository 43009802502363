<h6 class="outline-header">
  <span *ngIf="readingMode && !mobile">
    <i class="fas fa-bars"></i>
  </span>
  Outline
</h6>
<ul *ngIf="scrollableElements">
  <li *ngIf="scrollableElements.getStarted">
    <sbdl-button class="outline" (click)="scrollToElement(scrollableElements.getStarted)" (blur)="removeClass(scrollableElements.getStarted)">
      <i class="far fa-hand-point-right"></i>
      Get Started
    </sbdl-button>
  </li>
  <li *ngIf="scrollableElements.overview">
    <sbdl-button class="outline" (click)="scrollToElement(scrollableElements.overview)" (blur)="removeClass(scrollableElements.overview)">
      <i class="far fa-hand-point-right"></i>
      Overview
    </sbdl-button>
  </li>
  <li *ngIf="scrollableElements.steps" class="steps">
    <div class="steps-header">
      <sbdl-icon icon="steps"></sbdl-icon>
      Step-by-Step
    </div>
    <ol>
      <li *ngFor="let step of scrollableElements.steps; let i = index" >
        <sbdl-button class="step" (click)="scrollToElement(step.elementRef)" (blur)="removeClass(step.elementRef)">{{ step.title }}</sbdl-button>
      </li>
    </ol>
  </li>
  <li *ngIf="scrollableElements.topics">
      <div class="steps-header">
        <i class="far fa-chart-network"></i>
        Topics
      </div>
      <ol class="topics">
        <li *ngFor="let topic of scrollableElements.topics; let i = index" >
          <sbdl-button class="step" (click)="scrollToElement(topic.elementRef)" (blur)="removeClass(topic.elementRef)">{{ topic.title }}</sbdl-button>
        </li>
      </ol>
  </li>
  <li *ngIf="scrollableElements.customStrategySection">
    <sbdl-button class="outline" (click)="scrollToElement(scrollableElements.customStrategySection.elementRef)" (blur)="removeClass(scrollableElements.customStrategySection.elementRef)">
      <sbdl-icon *ngIf="scrollableElements.customStrategySection.sbdlIcon" [icon]="scrollableElements.customStrategySection.sbdlIcon"></sbdl-icon>
      <i *ngIf="scrollableElements.customStrategySection.fontAwesomeIcon" class="far {{scrollableElements.customStrategySection.fontAwesomeIcon}}"></i>
      {{ scrollableElements.customStrategySection.title }}
    </sbdl-button>
  </li>
  <li *ngIf="scrollableElements.attachments">
    <sbdl-button class="outline" (click)="scrollToElement(scrollableElements.attachments)" (blur)="removeClass(scrollableElements.attachments)">
      <i class="far fa-paperclip"></i>
      Attachments
    </sbdl-button>
  </li>
  <li *ngIf="scrollableElements.differentiation">
    <sbdl-button class="outline" (click)="scrollToElement(scrollableElements.differentiation)" (blur)="removeClass(scrollableElements.differentiation)">
      <i class="far fa-universal-access"></i>
      {{ model.differentiation.performanceBasedDifferentiation ? "Differentiation" : "Accessibility Strategies Used" }}
    </sbdl-button>
  </li>
  <li *ngIf="scrollableElements.comments">
    <sbdl-button class="outline" (click)="scrollToElement(scrollableElements.comments)" (blur)="removeClass(scrollableElements.comments)">
      <i class="far {{ commentsSection.fontAwesomeIcon }}"></i>
      {{ commentsSection.title }}
    </sbdl-button>
  </li>
  <li *ngIf="scrollableElements.formativeAssessmentProcess">
    <sbdl-button class="outline" (click)="scrollToElement(scrollableElements.formativeAssessmentProcess)" (blur)="removeClass(scrollableElements.formativeAssessmentProcess)">
      <sbdl-icon icon="strategies"></sbdl-icon>
      {{ isProfessional ? 'Formative Assessment Strategies' : 'Formative Assessment Process' }}
    </sbdl-button>
  </li>
  <li *ngFor="let section of scrollableElements.sections" >
    <sbdl-button class="outline" (click)="scrollToElement(section.elementRef)" (blur)="removeClass(section.elementRef)">
      <sbdl-icon *ngIf="section.sbdlIcon" [icon]="section.sbdlIcon"></sbdl-icon>
      <i *ngIf="section.fontAwesomeIcon" class="far {{section.fontAwesomeIcon}}"></i>
      {{ section.title }}
    </sbdl-button>
  </li>
  <li *ngIf="scrollableElements.assessmentInfo">
    <sbdl-button class="outline" (click)="scrollToElement(scrollableElements.assessmentInfo)" (blur)="removeClass(scrollableElements.assessmentInfo)">
      <i class="far fa-question-circle"></i>
      What are Interim Assessments?
    </sbdl-button>
  </li>
</ul>
